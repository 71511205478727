function setUpSpecialNavs() {
  function t() {
    document.querySelector('.content-tint').classList.remove('on'),
      document.querySelector('.selected-nav').click(),
      setTimeout(function () {
        document.querySelector('.content-tint').remove();
      }, 10);
  }
  document.querySelectorAll('.navbar-toggler').forEach(function (e) {
    e.addEventListener('click', function (e) {
      var t,
        o,
        l,
        n = e.target.closest('nav'),
        i = n.querySelector('ul.site-navigation'),
        a = i.parentNode.innerHTML;
      i.parentNode.matches('.fullscreen-nav, .sidebar-nav') &&
        (e.stopPropagation(),
        i.parentNode.classList.add('nav-special'),
        e.target.classList.contains('selected-nav')
          ? (document.querySelector('.close-special-menu').remove(),
            document
              .querySelector('.blocsapp-special-menu blocsnav')
              .classList.remove('open'),
            document
              .querySelector('.selected-nav')
              .classList.remove('selected-nav'),
            setTimeout(function () {
              document.querySelector('.blocsapp-special-menu').remove(),
                document.body.classList.remove('lock-scroll'),
                document
                  .querySelector('.nav-special')
                  .classList.remove('nav-special');
            }, 300))
          : (e.target.classList.add('selected-nav'),
            (n = n
              .getAttribute('class')
              .replace('navbar', '')
              .replace('row', '')
              .replace('hover-open-submenu', '')),
            (i = i.parentNode
              .getAttribute('class')
              .replace('navbar-collapse', '')
              .replace('collapse', '')
              .replace('collapsing', '')),
            document.querySelector('.content-tint') ||
              document.body.insertAdjacentHTML(
                'beforeend',
                '<div class="content-tint"></div>',
              ),
            document
              .querySelector('.page-container')
              .insertAdjacentHTML(
                'beforebegin',
                '<div class="blocsapp-special-menu ' +
                  n +
                  '"><blocsnav class="' +
                  i +
                  '">' +
                  a +
                  '</div>',
              ),
            document
              .querySelector('blocsnav')
              .insertAdjacentHTML(
                'afterbegin',
                '<a class="close-special-menu animated fadeIn animDelay06"><div class="close-icon"></div></a>',
              ),
            (t = 0),
            (o = 60),
            (l = 'fadeInRight'),
            document
              .querySelector('.blocsapp-special-menu blocsnav')
              .classList.contains('fullscreen-nav')
              ? ((l = 'fadeIn'), (o = 100))
              : document
                  .querySelector('.blocsapp-special-menu')
                  .classList.contains('nav-invert') && (l = 'fadeInLeft'),
            document
              .querySelectorAll('.blocsapp-special-menu blocsnav li')
              .forEach((e) => {
                e.parentNode.classList.contains('dropdown-menu')
                  ? e.classList.add.apply(e.classList, ['animated', 'fadeIn'])
                  : ((t += o),
                    e.classList.add.apply(e.classList, ['animated', l]),
                    e.setAttribute('style', 'animation-delay:' + t + 'ms'));
              }),
            document
              .querySelector('blocsnav')
              .querySelectorAll('.dropdown')
              .forEach(function (dropdown) {
                addDropdownEvent(dropdown);
              }),
            setTimeout(function () {
              document
                .querySelector('.blocsapp-special-menu blocsnav')
                .classList.add('open'),
                document.querySelector('.content-tint').classList.add('on'),
                document.body.classList.add('lock-scroll');
            }, 10)));
    });
  }),
    delegateSelector('body', 'click', '.blocsapp-special-menu a', function (e) {
      e.target.closest('.dropdown-toggle') || t();
    }),
    delegateSelector(
      'body',
      'mousedown touchstart',
      '.content-tint, .close-special-menu, .close-special-menu .close-icon',
      function (e) {
        t();
      },
    );
}
function setUpNavExtras() {
  function t(e) {
    var t,
      o,
      l = e.closest('.dropdown-menu');
    l &&
      ((t = e.parentNode.querySelector('.dropdown-menu')),
      l.parentNode.classList.contains('navbar-nav') ||
        e.closest('.nav-special') ||
        ((o = l.offsetWidth - 2),
        (e = e.parentNode.querySelector('.dropdown-menu').offsetWidth),
        t.classList.remove('submenu-left'),
        0 == e && (e = 160),
        l.getBoundingClientRect().left + e + o > window.innerWidth &&
          t.classList.add('submenu-left')));
  }
  delegateSelector(
    'body',
    'click',
    '.dropdown-menu a.dropdown-toggle',
    function (e) {
      t(e.target);
    },
  ),
    document
      .querySelectorAll('.dropdown-menu a.dropdown-toggle')
      .forEach(function (e) {
        e.addEventListener('click', function (e) {
          t(e.target);
        });
      }),
    document
      .querySelectorAll('ul.dropdown-menu [data-toggle=dropdown]')
      .forEach(function (e) {
        e.addEventListener('click', function (e) {
          e.stopPropagation();
        });
      });
}
function scrollToTarget(e, t) {
  var n = window.scrollY || document.documentElement.scrollTop,
    i = 0,
    o = 1e3,
    a = 0;
  0 == e
    ? (a = t.closest('.bloc').offsetHeight)
    : 2 == e
    ? (a = document.documentElement.scrollHeight)
    : 1 != e &&
      ((a =
        document.querySelector(e).getBoundingClientRect().top + window.scrollY),
      (e = document.querySelector('.sticky-nav')) && (a -= e.offsetHeight)),
    t.matches('[data-scroll-speed]') &&
      (o = parseInt(t.getAttribute('data-scroll-speed')));
  var c = Math.max(0.1, Math.min(Math.abs(n - a) / o, 0.8));
  !(function e() {
    var t = (i += 1 / 60) / c,
      o = Math.sin(t * (Math.PI / 2)),
      l = a;
    t < 1 && (requestAnimFrame(e), (l = n + (a - n) * o)),
      window.scrollTo(0, l);
  })();
}
function stickyNavToggle() {
  var e,
    t,
    o,
    l,
    n = document.querySelector('.sticky-nav');
  n &&
    ((e = n.getBoundingClientRect().top + window.scrollY),
    (t = ['sticky']),
    (o = document.querySelector('.page-container')),
    (l = n.classList.contains('fill-bloc-top-edge')) &&
      ((o = document.querySelector(
        '.fill-bloc-top-edge.sticky-nav',
      ).parentNode),
      (t = ['sticky', 'animated', 'fadeIn'])),
    n.classList.contains('sticky') &&
      (e = n.getAttribute('data-original-offset')),
    e < (window.pageYOffset || document.documentElement.scrollTop)
      ? n.classList.contains('sticky') ||
        (n.classList.add.apply(n.classList, t),
        n.setAttribute('data-original-offset', e),
        (e = n.offsetHeight),
        l &&
          ((n.style.background = getBlocBgColor(o)),
          (e += parseInt(
            window.getComputedStyle(o, null).getPropertyValue('padding-top'),
          ))),
        (o.style.paddingTop = e + 'px'))
      : n.classList.contains('sticky') &&
        (n.classList.remove.apply(n.classList, t),
        n.removeAttribute('style'),
        o.removeAttribute('style')));
}
function getBlocBgColor(e) {
  var t = window.getComputedStyle(e, null).getPropertyValue('background-color');
  return (
    e.classList.contains('b-parallax') &&
      (t = window
        .getComputedStyle(e.querySelector('.parallax'), null)
        .getPropertyValue('background-color')),
    'rgba(0, 0, 0, 0)' == t && (t = '#FFFFFF'),
    t
  );
}
function hideAll() {
  document.querySelectorAll('.animated').forEach((e) => {
    (!document.body.classList.contains('mob-disable-anim') ||
      (document.body.classList.contains('mob-disable-anim') &&
        767 < window.innerWidth)) &&
      e.getBoundingClientRect().top + e.offsetHeight / 3 > window.innerHeight &&
      (e.classList.remove('animated'), e.classList.add('hideMe'));
  });
}
function inViewCheck() {
  const e = [].slice.call(document.querySelectorAll('.hideMe'), 0).reverse();
  e.forEach((t) => {
    var e,
      o = t.getBoundingClientRect().top + window.scrollY,
      l = o + t.offsetHeight,
      n = window.pageYOffset + window.innerHeight;
    t.offsetHeight > window.innerHeight && (l = o),
      l < n &&
        ((e = t.className.replace('hideMe', 'animated')),
        (t.style.visibility = 'hidden'),
        t.removeAttribute('class'),
        setTimeout(function () {
          (t.style.visibility = 'visible'), t.setAttribute('class', e);
        }, 0.01),
        [
          'webkitAnimationEnd',
          'mozAnimationEnd',
          'oAnimationEnd',
          'animationEnd',
        ].forEach(function (e) {
          window.addEventListener(e, function (e) {
            t.classList.remove(t.getAttribute('data-appear-anim-style'));
          });
        }));
  });
}
function scrollBtnVisible() {
  var e = document.querySelector('.scrollToTop');
  e &&
    (window.pageYOffset > window.innerHeight / 3
      ? e.classList.contains('showScrollTop') ||
        e.classList.add('showScrollTop')
      : e.classList.remove('showScrollTop'));
}
function setUpVisibilityToggle() {
  document.querySelectorAll('[data-toggle-visibility]').forEach(function (e) {
    e.addEventListener('click', function (e) {
      e.preventDefault();
      var t = e.currentTarget.getAttribute('data-toggle-visibility'),
        e = [t];
      -1 != t.indexOf(',') && (e = t.split(',')),
        e.forEach(function (e) {
          var t, o;
          (t = document.getElementById(e)) &&
            (t.classList.contains('toggled-item') ||
              t.classList.add('toggled-item'),
            '0px' ==
              window.getComputedStyle(t, null).getPropertyValue('height') ||
            t.classList.contains('object-hidden')
              ? (t.classList.remove('object-hidden'),
                t.style.removeProperty('display'),
                (t.style.height = 'auto'),
                (o = t.clientHeight + 'px'),
                (t.style.height = '0px'),
                t.offsetHeight,
                t.classList.remove('toggled-item-hidden'),
                setTimeout(function () {
                  t.style.height = o;
                }, 0),
                setTimeout(function () {
                  (t.style.minHeight = o), t.style.removeProperty('height');
                }, 360))
              : ((t.style.height = t.scrollHeight + 'px'),
                t.offsetHeight,
                t.style.removeProperty('min-height'),
                t.classList.add('toggled-item-hidden'),
                window.setTimeout(function () {
                  (t.style.height = '0'),
                    0 == t.style.height && (t.style.display = 'none');
                }, 0)),
            reCalculateParallax());
        });
    });
  });
}
function setUpClassToggle() {
  document.querySelectorAll('[data-toggle-class-target]').forEach(function (e) {
    e.addEventListener('click', function (e) {
      e.preventDefault();
      var t = e.currentTarget.getAttribute('data-toggle-class-target'),
        o = e.currentTarget.getAttribute('data-toggle-class');
      o.length &&
        (-1 != t.indexOf(',')
          ? t.split(',').forEach(function (e) {
              document.getElementById(e).classList.toggle(o);
            })
          : document.getElementById(t).classList.toggle(o),
        reCalculateParallax());
    });
  });
}
function setUpLightBox() {
  function r() {
    var e = document.querySelector('#lightbox-image'),
      t = document.querySelector('.lightbox-caption'),
      o = document.querySelector('#lightbox-video-container');
    (e.style.display = 'block'),
      (t.style.display = 'none'),
      (o.style.display = 'none');
    var l,
      n = targetJSLightbox.getAttribute('data-lightbox');
    '.mp4' == n.substring(n.length - 4)
      ? ((l = ''),
        1 == targetJSLightbox.getAttribute('data-autoplay') && (l = 'autoplay'),
        (e.style.display = 'none'),
        (o.style.display = 'block'),
        (o.innerHTML =
          '<video controls ' +
          l +
          ' class="embed-responsive-item"><source id="lightbox-video" src="' +
          n +
          '" type="video/mp4"></video>'))
      : (e.setAttribute('src', n),
        (i = targetJSLightbox.getAttribute('data-caption')) &&
          ((t.innerHTML = i), (t.style.display = 'block')));
    var n = document.querySelector('.prev-lightbox'),
      i = document.querySelector('.next-lightbox');
    (n.style.display = 'block'), (i.style.display = 'block');
    t = document.querySelectorAll('a[data-lightbox]');
    targetJSLightbox.getAttribute('data-gallery-id') &&
      ((a = targetJSLightbox.getAttribute('data-gallery-id')),
      (t = document.querySelectorAll('a[data-gallery-id="' + a + '"]')));
    var a = Array.from(t).indexOf(targetJSLightbox);
    0 == a
      ? ((n.style.display = 'none'),
        1 == t.length && (i.style.display = 'none'))
      : a == t.length - 1 && (i.style.display = 'none');
  }
  window.targetJSLightbox,
    document.querySelectorAll('[data-lightbox]').forEach(function (s) {
      s.addEventListener('click', function (e) {
        e.preventDefault(), (targetJSLightbox = s);
        var t = targetJSLightbox.getAttribute('data-lightbox'),
          o = targetJSLightbox.getAttribute('data-caption'),
          l = targetJSLightbox.getAttribute('data-frame'),
          n = '',
          i = '';
        1 == targetJSLightbox.getAttribute('data-autoplay') && (i = 'autoplay');
        var a = targetJSLightbox.querySelector('img');
        a && a.classList.contains('img-protected') && (n = 'img-protected');
        e = document.querySelector('#lightbox-modal');
        e && e.remove();
        (a =
          '<svg class="lightbox-close-svg" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 32 32"><path class="lightbox-close-icon" d="M4,4L28,28"/><path class="lightbox-close-icon" d="M28,4L4,28"/></svg>'),
          (o =
            '<div id="lightbox-modal" class="modal fade"><div class="modal-dialog modal-dialog-centered modal-lg"><div class="modal-content ' +
            l +
            ' blocs-lb-container"><button id="blocs-lightbox-close-btn" type="button" class="close-lightbox" data-bs-dismiss="modal" aria-label="Close">' +
            a +
            '</button><div class="modal-body"><a href="#" class="prev-lightbox" aria-label="prev"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 32 32"><path class="lightbox-nav-icon lightbox-prev-icon" d="M22,2L9,16,22,30"/></svg></a><a href="#" class="next-lightbox" aria-label="next"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 32 32"><path class="lightbox-nav-icon lightbox-next-icon" d="M10.344,2l13,14-13,14"/></svg></a><img id="lightbox-image" class="img-fluid mx-auto ' +
            n +
            '" src="' +
            t +
            '"><div id="lightbox-video-container" class="embed-responsive embed-responsive-16by9"><video controls ' +
            i +
            ' class="embed-responsive-item"><source id="lightbox-video" src="' +
            t +
            '" type="video/mp4"></video></div><p class="lightbox-caption">' +
            o +
            '</p></div></div></div></div>');
        document.body.insertAdjacentHTML('beforeend', o),
          document
            .querySelectorAll('.next-lightbox, .prev-lightbox')
            .forEach(function (o) {
              o.addEventListener('click', function (e) {
                e.preventDefault();
                e = document.querySelectorAll('a[data-lightbox]');
                targetJSLightbox.getAttribute('data-gallery-id') &&
                  ((t = targetJSLightbox.getAttribute('data-gallery-id')),
                  (e = document.querySelectorAll(
                    'a[data-gallery-id="' + t + '"]',
                  )));
                var t = Array.from(e).indexOf(targetJSLightbox) + 1;
                o.classList.contains('prev-lightbox') && (t -= 2),
                  -1 < t && e.length > t && ((targetJSLightbox = e[t]), r());
              });
            }),
          (function () {
            const e = document.getElementById('lightbox-image');
            if (e) {
              let o = 0,
                t = 0,
                l = 0,
                n = 0;
              e.addEventListener(
                'touchstart',
                function (e) {
                  (o = e.changedTouches[0].screenX),
                    (t = e.changedTouches[0].screenY);
                },
                !1,
              ),
                e.addEventListener(
                  'touchend',
                  function (e) {
                    var t;
                    (l = e.changedTouches[0].screenX),
                      (n = e.changedTouches[0].screenY),
                      (t = document.querySelector('.prev-lightbox')),
                      (e = document.querySelector('.next-lightbox')),
                      l <= o && 'none' != e.style.display && e.click(),
                      l >= o && 'none' != t.style.display && t.click();
                  },
                  !1,
                );
            }
          })(),
          (e = document.querySelector('#lightbox-modal')),
          'fullscreen-lb' == l &&
            (e.classList.add('fullscreen-modal'),
            document.querySelector('#blocs-lightbox-close-btn').remove(),
            e.insertAdjacentHTML(
              'beforeend',
              '<a class="close-full-screen-modal animated fadeIn" style="animation-delay:0.5s;" data-bs-dismiss="modal">' +
                a +
                '</a>',
            )),
          r();
        c.show(),
          n.length &&
            ((n = document.querySelector(
              '#lightbox-modal .img-protected',
            )).addEventListener('contextmenu', function (e) {
              e.preventDefault();
            }),
            n.addEventListener('mousedown', function (e) {
              e.preventDefault();
            })),
          delegateSelector(
            'body',
            'click',
            '[data-bs-dismiss="modal"]',
            function (e) {
              c.hide();
            },
          );
      });
    }),
    window.addEventListener('keydown', (e) => {
      var t = document.querySelector('.prev-lightbox');
      37 == e.which || 39 == e.which
        ? (39 == e.which && (t = document.querySelector('.next-lightbox')),
          'none' != t.style.display && t.click())
        : 27 == e.which &&
          document.getElementById('blocs-lightbox-close-btn').click();
    });
}
function setUpImgProtection() {
  document.querySelectorAll('.img-protected').forEach(function (e) {
    e.addEventListener('contextmenu', function (e) {
      e.preventDefault();
    }),
      e.addEventListener('mousedown', function (e) {
        e.preventDefault();
      });
  });
}
function reCalculateParallax() {
  var e;
  document.querySelector('.b-parallax') &&
    ((e = document.querySelectorAll('.parallax__container .parallax')).forEach(
      (e) => {
        e.style.height = '100%';
      },
    ),
    setTimeout(function () {
      calculateHeight(e, 3);
    }, 400));
}
function scrollToPolyFill() {
  var e,
    t,
    l,
    c,
    s,
    o,
    r = window,
    d = document;
  function u(e, t) {
    (this.scrollLeft = e), (this.scrollTop = t);
  }
  function n(e) {
    if (
      null === e ||
      'object' != typeof e ||
      void 0 === e.behavior ||
      'auto' === e.behavior ||
      'instant' === e.behavior
    )
      return !0;
    if ('object' == typeof e && 'smooth' === e.behavior) return !1;
    throw new TypeError(
      'behavior member of ScrollOptions ' +
        e.behavior +
        ' is not a valid value for enumeration ScrollBehavior.',
    );
  }
  function i(e, t) {
    return 'Y' === t
      ? e.clientHeight + o < e.scrollHeight
      : 'X' === t
      ? e.clientWidth + o < e.scrollWidth
      : void 0;
  }
  function a(e, t) {
    t = r.getComputedStyle(e, null)['overflow' + t];
    return 'auto' === t || 'scroll' === t;
  }
  function g(e) {
    var t = (s() - e.startTime) / l,
      o = (t = 1 < t ? 1 : t),
      t = 0.5 * (1 - Math.cos(Math.PI * o)),
      o = e.startX + (e.x - e.startX) * t,
      t = e.startY + (e.y - e.startY) * t;
    e.method.call(e.scrollable, o, t),
      (o === e.x && t === e.y) || r.requestAnimationFrame(g.bind(r, e));
  }
  function p(e, t, o) {
    var l,
      n,
      i,
      a = s(),
      e =
        e === d.body
          ? ((n = (l = r).scrollX || r.pageXOffset),
            (i = r.scrollY || r.pageYOffset),
            c.scroll)
          : ((n = (l = e).scrollLeft), (i = e.scrollTop), u);
    g({
      scrollable: l,
      method: e,
      startTime: a,
      startX: n,
      startY: i,
      x: t,
      y: o,
    });
  }
  ('scrollBehavior' in d.documentElement.style &&
    !0 !== r.__forceSmoothScrollPolyfill__) ||
    ((t = r.HTMLElement || r.Element),
    (l = 468),
    (c = {
      scroll: r.scroll || r.scrollTo,
      scrollBy: r.scrollBy,
      elementScroll: t.prototype.scroll || u,
      scrollIntoView: t.prototype.scrollIntoView,
    }),
    (s =
      r.performance && r.performance.now
        ? r.performance.now.bind(r.performance)
        : Date.now),
    (e = r.navigator.userAgent),
    (o = new RegExp(['MSIE ', 'Trident/', 'Edge/'].join('|')).test(e) ? 1 : 0),
    (r.scroll = r.scrollTo =
      function () {
        void 0 !== arguments[0] &&
          (!0 !== n(arguments[0])
            ? p.call(
                r,
                d.body,
                void 0 !== arguments[0].left
                  ? ~~arguments[0].left
                  : r.scrollX || r.pageXOffset,
                void 0 !== arguments[0].top
                  ? ~~arguments[0].top
                  : r.scrollY || r.pageYOffset,
              )
            : c.scroll.call(
                r,
                void 0 !== arguments[0].left
                  ? arguments[0].left
                  : 'object' != typeof arguments[0]
                  ? arguments[0]
                  : r.scrollX || r.pageXOffset,
                void 0 !== arguments[0].top
                  ? arguments[0].top
                  : void 0 !== arguments[1]
                  ? arguments[1]
                  : r.scrollY || r.pageYOffset,
              ));
      }),
    (r.scrollBy = function () {
      void 0 !== arguments[0] &&
        (n(arguments[0])
          ? c.scrollBy.call(
              r,
              void 0 !== arguments[0].left
                ? arguments[0].left
                : 'object' != typeof arguments[0]
                ? arguments[0]
                : 0,
              void 0 !== arguments[0].top
                ? arguments[0].top
                : void 0 !== arguments[1]
                ? arguments[1]
                : 0,
            )
          : p.call(
              r,
              d.body,
              ~~arguments[0].left + (r.scrollX || r.pageXOffset),
              ~~arguments[0].top + (r.scrollY || r.pageYOffset),
            ));
    }),
    (t.prototype.scroll = t.prototype.scrollTo =
      function () {
        if (void 0 !== arguments[0])
          if (!0 !== n(arguments[0])) {
            var e = arguments[0].left,
              t = arguments[0].top;
            p.call(
              this,
              this,
              void 0 === e ? this.scrollLeft : ~~e,
              void 0 === t ? this.scrollTop : ~~t,
            );
          } else {
            if ('number' == typeof arguments[0] && void 0 === arguments[1])
              throw new SyntaxError('Value could not be converted');
            c.elementScroll.call(
              this,
              void 0 !== arguments[0].left
                ? ~~arguments[0].left
                : 'object' != typeof arguments[0]
                ? ~~arguments[0]
                : this.scrollLeft,
              void 0 !== arguments[0].top
                ? ~~arguments[0].top
                : void 0 !== arguments[1]
                ? ~~arguments[1]
                : this.scrollTop,
            );
          }
      }),
    (t.prototype.scrollBy = function () {
      void 0 !== arguments[0] &&
        (!0 !== n(arguments[0])
          ? this.scroll({
              left: ~~arguments[0].left + this.scrollLeft,
              top: ~~arguments[0].top + this.scrollTop,
              behavior: arguments[0].behavior,
            })
          : c.elementScroll.call(
              this,
              void 0 !== arguments[0].left
                ? ~~arguments[0].left + this.scrollLeft
                : ~~arguments[0] + this.scrollLeft,
              void 0 !== arguments[0].top
                ? ~~arguments[0].top + this.scrollTop
                : ~~arguments[1] + this.scrollTop,
            ));
    }),
    (t.prototype.scrollIntoView = function () {
      var e, t, o;
      !0 !== n(arguments[0])
        ? ((t = (e = (function (e) {
            for (
              ;
              e !== d.body &&
              !1 ===
                ((o = void 0),
                (o = i((t = e), 'Y') && a(t, 'Y')),
                (t = i(t, 'X') && a(t, 'X')),
                o || t);

            )
              e = e.parentNode || e.host;
            var t, o;
            return e;
          })(this)).getBoundingClientRect()),
          (o = this.getBoundingClientRect()),
          e !== d.body
            ? (p.call(
                this,
                e,
                e.scrollLeft + o.left - t.left,
                e.scrollTop + o.top - t.top,
              ),
              'fixed' !== r.getComputedStyle(e).position &&
                r.scrollBy({ left: t.left, top: t.top, behavior: 'smooth' }))
            : r.scrollBy({ left: o.left, top: o.top, behavior: 'smooth' }))
        : c.scrollIntoView.call(this, void 0 === arguments[0] || arguments[0]);
    }));
}
function delegateSelector(t, e, l, n) {
  e.split(' ').forEach((o) => {
    var e = document.querySelectorAll(t);
    [].forEach.call(e, function (e, t) {
      e.addEventListener(o, function (e) {
        e.target.matches(l) && n(e);
      });
    });
  });
}
document.addEventListener('DOMContentLoaded', function () {
  setUpNavExtras(),
    setUpSpecialNavs(),
    setUpLightBox(),
    setUpVisibilityToggle(),
    setUpClassToggle(),
    setUpImgProtection(),
    [].slice
      .call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      .map(function (e) {
        return new bootstrap.Tooltip(e);
      }),
    delegateSelector('body', 'hidden.bs.modal', '.modal', function (e) {
      e.target
        .querySelectorAll('.embed-responsive-item')
        .forEach((videoItem) => {
          if (videoItem.tagName == 'VIDEO') {
            videoItem.pause();
          } else {
            videoItem.setAttribute('src', videoItem.getAttribute('data-src'));
          }
        });
    }),
    document
      .querySelectorAll('a[onclick^="scrollToTarget"]')
      .forEach(function (e) {
        e.addEventListener('click', function (e) {
          e.preventDefault();
        });
      }),
    document
      .querySelectorAll('.nav-item [data-active-page]')
      .forEach(function (e) {
        e.classList.add(e.getAttribute('data-active-page'));
      }),
    window.Element &&
      !Element.prototype.closest &&
      (Element.prototype.closest = function (e) {
        var t,
          o = (this.document || this.ownerDocument).querySelectorAll(e),
          l = this;
        do {
          for (t = o.length; 0 <= --t && o.item(t) !== l; );
        } while (t < 0 && (l = l.parentElement));
        return l;
      }),
    Element.prototype.matches ||
      (Element.prototype.matches =
        Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector),
    window.matchMedia('(prefers-color-scheme: dark)').matches &&
      document.body.classList.add('dark-theme'),
    scrollToPolyFill();
}),
  window.addEventListener('load', function () {
    hideAll(),
      inViewCheck(),
      window.addEventListener('scroll', function () {
        inViewCheck(), scrollBtnVisible(), stickyNavToggle();
      });
    var e = document.getElementById('page-loading-blocs-notifaction');
    e && e.classList.add('preloader-complete');
  });
function addDropdownEvent(s) {
  s.addEventListener('hide.bs.dropdown', function (s) {
    this.classList.contains('has-child-dropdown-show') &&
      (this.classList.remove('has-child-dropdown-show'), s.preventDefault()),
      s.clickEvent &&
        s.clickEvent
          .composedPath()
          .some(
            (s) =>
              s.classList &&
              s.classList.contains('dropdown-toggle') &&
              s.parentNode.closest('.dropdown-toggle'),
          ) &&
        s.preventDefault(),
      s.stopPropagation();
  });
}
window.requestAnimFrame =
  window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  function (e) {
    window.setTimeout(e, 1e3 / 60);
  };
